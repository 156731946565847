<template lang="html">
  <div>
    <v-toolbar flat dense>
      <v-btn outlined small class="mr-4" @click="setToday" title="hari ini">
        <v-icon>mdi-calendar-today</v-icon>
      </v-btn>
      <v-btn fab text small @click="prev">
        <v-icon small>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn fab text small @click="next">
        <v-icon small>mdi-chevron-right</v-icon>
      </v-btn>
      <v-toolbar-title v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </v-toolbar-title>
      <v-toolbar-title v-else>
        {{ currentMonth }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-calendar
    @click:date="goToAktivitas"
    ref="calendar"
    color="primary"
    type="month"
    v-model="selectedDate"
    @change="updateRange"
    style="height:300px;overflow-x:auto"
    >
    <template v-slot:day="{date }">
      <v-sheet :class="getConditionalDate(date)" style="margin-top:-45px;" tile >
        <div class="d-flex full-width full-height align-end" style="height:100%">
          <template v-for="(ev, i) in customEvents">
            <v-tooltip top :key="i">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                :key="i"
                v-if="ev.date == date"
                :color="ev.color"
                size="10"
                class="ml-1 mb-1"
                v-bind="attrs"
                v-on="on"
                />
              </template>
              <span>{{ev.name}}</span>
            </v-tooltip>
          </template>
        </div>
      </v-sheet>
    </template>
    </v-calendar>
  </div>
</template>

<script>
import PelaksanaService from '@/services/PelaksanaService'
import moment from 'moment'
import router from '@/router'

export default {
  data(){
    return{
      selectedDate:'',
      customEvents:[],
      batasTanggal:null,
    }
  },

  computed:{
    currentMonth(){
      moment.locale('id')
      return moment().format('MMMM YYYY')
    }
  },

  created(){
    PelaksanaService.getTanggalInputAktivitas().then(response => {
      this.batasTanggal = response.data.hmin7
    })
  },

  methods:{
    goToAktivitas({date}){
      console.log(date)
      router.push('/pelaksanaan/input-aktivitas')
    },

    setToday() {
      this.selectedDate = ''
    },

    prev() {
      this.$refs.calendar.prev()
    },

    next() {
      this.$refs.calendar.next()
    },

    updateRange({start, end}){
      this.startDate = start.date
      this.endDate = end.date
      this.getCalendar(start.date,end.date)
    },

    compareDate(a, b) {
      let dateA = new Date(a)
      let dateB = b ? new Date(b) : new Date()
      dateA.setHours(0, 0, 0, 0)
      dateB.setHours(0, 0, 0, 0)
      if (dateA.getTime() > dateB.getTime()) {
        return 1
      } else if (dateA.getTime() < dateB.getTime()) {
        return -1
      }
      return 0
    },

    getCalendar(startDate, endDate){
      this.customEvents = []
      this.loadingCalendar=true
      PelaksanaService.inputAktivitasGetCalendar({
        from: startDate,
        to: endDate
      }).then(response => {
        let events = []
        let customEvents = []
        let rawEvents = response.data.data

        for (let i = 0; i < rawEvents.length; i++) {
          let color = rawEvents[i].class.replace('event-', '')

          switch (color) {
            case 'important':
              color = "red"
              break;
            case 'special':
              color = "purple lighten-2"
              break;
          }
          customEvents.push({
            name: rawEvents[i].title,
            count: rawEvents[i].title.match(/\d+/) ? rawEvents[i].title.match(/\d+/)[0] : 0,
            date: rawEvents[i].tanggal,
            color: color
          })

        }
        this.customEvents = customEvents
      }).finally(()=>{
        this.loadingCalendar=false
      })
    },

    getConditionalDate(date) {
      let allowed = this.compareDate(date, null) == -1 &&
        this.compareDate(date, this.batasTanggal) == 1 ||
        this.compareDate(date, this.batasTanggal) == 0 ||
        this.compareDate(date, null) == 0

      let idx = this.customEvents.findIndex( x => this.compareDate(x.date,date) == 0)
      let hasEvent = idx >= 0 ? true : false

      let today = this.compareDate(date, this.selectedDate) == 0

      return {
        "green lighten-3": hasEvent && allowed,
        "amber lighten-3": hasEvent && !allowed,
        'blue lighten-4': allowed,
        "orange lighten-1": today,
        'fill-height': true,
        'fill-width': true
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
