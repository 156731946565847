import request from '@/utils/request'
const NewsService = {
  fetchNews,
}

function fetchNews(){
  return request({
    url:'/news/news',
    method:'get'
  })
}

export default NewsService
