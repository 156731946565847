<!--
✓ Notifikasi  (validasi, aktivitas, SKP)
✓ Aktivitas (kalender)
✓ SKP
✓ Profile (data diri)
✓ Rata - rata jam kehadiran
-->
<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"

  >
  <div v-if="this.loadedData != this.jumlahData">
    <v-progress-linear :value="this.loadedData/this.jumlahData*100"></v-progress-linear>
    <p style="font-size:12px" class="error--text text--lighten-2">{{errorMsg}}</p>
    <p style="font-size:12px" class="default--text text--lighten-2">Loaded {{this.loadedData}} from {{this.jumlahData}}</p>
  </div>

    <!-- <v-btn @click="alert('test snackbar')">test</v-btn> -->
    <div v-show="hbday">
      <div class="confetti" v-for="i in 100" :key="i"></div>
    </div>

    <!-- Pengumuman Batas Waktu -->
    <!-- <v-card justify="center" class="ml-5 mr-5">
      <div class="pa-5">
      <h1>Perhatian <v-icon>mdi-alert-circle</v-icon></h1>
      <font color="black">Batas waktu Lapor SKP Triwulan: <font color="red">4 April 2023 </font> </font><br>
      <font color="black">Batas waktu Validasi Aktivitas Bulan Maret : <font color="red">5 April 2023 </font> </font><br>
      <font color="black">Batas waktu Validasi SKP Triwulan: <font color="red">5 April 2023 </font> </font>
      </div>
    </v-card> -->
    <!-- <v-card justify="center" class="ml-5 mr-5" v-if="anggota_not_pokja.length > 0">
      <div class="pa-5">
      <h1>Perhatian <v-icon>mdi-alert-circle</v-icon></h1>
      <h3><font color="red">Masih terdapat pegawai yang belum masuk ke dalam tim kelompok kerja (pokja):</font></h3>
      <div v-for="(d,i) in anggota_not_pokja" v-bind:key="i">
        <font v-bind:key="i" color="black">{{ (i+1) }}. {{d.peg_nip}} - {{d.peg_nama}} </font><br>
      </div>
      </div>
    </v-card> -->
    <!-- <v-row class="mx-2">
      <v-col cols="6">
        <v-card>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="12">
                <h3>Survey Minat Jabatan Yang Diminati</h3>
                <v-btn class="mt-2" color="primary" @click="openSurvey()">
                  <v-icon>mdi-file-document</v-icon> &emsp;
                  Isi survey minat jabatan yang diminati
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row> -->
    <v-row justify="center">
      <v-col
        cols="12"
        lg="12"
      >
        <!-- big card -->
        <v-row class="mx-2">

          <!-- dta diri -->
          <v-col
            cols="12"
            md="5"
            sm="12"
            lg="3"
          >
            <base-material-card
              color="indigo dark-1"
              icon="mdi-account"
              height='fit-content'
            >
              <div class="d-flex flex-column">
                <div class="d-flex justify-center mb-5">
                  <div v-if="loadingBtn.dataPegawai">
                    <v-row class="mt-5">
                      <v-col>
                        <v-skeleton-loader
                        class="mx-auto"
                        min-width="200"
                        type="image"
                        ></v-skeleton-loader>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else>
                  <v-avatar tile :rounded="false" height="200" width="133" style="margin-top:-40px">
                    <v-img :src="avatarPhoto" @error="replacePhoto(dataDiri.foto)" class="yellow darken-2">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-img :src="require('@/assets/logo10.png')"></v-img>
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                  </div>
                </div>
                <div class="d-flex" style="margin-top:-50px" v-if="activateBtn">
                  <v-spacer></v-spacer>
                  <!-- <v-btn icon small tile title="profil"  class="elevation-2" color="green darken-1">
                    <v-icon>mdi-account</v-icon>
                  </v-btn> -->
                  {{ activateBtn }}
                  <v-btn @click="startHbday()" big fab dark class="elevation-2" color="orange">
                    <v-icon>mdi-trumpet</v-icon>
                  </v-btn>
                </div>
                <div v-if="loadingBtn.dataPegawai">
                  <v-row class="mt-5">
                    <v-col v-for="i in [1,2,3,4,5,6]" :key="i">
                      <v-skeleton-loader
                      class="mx-auto"
                      min-width="200"
                      type="paragraph"
                      height="15"
                      ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <table sytle="max-height:100%; overflow-y:scroll">
                    <tr v-for="(value, name , index) in dataDiriCloned" :key="index" class="text-body grey--text text--darken-2">
                      <!-- hidden/deleted value: plt_id, survey_flag, jenis_pbj, jenis_ppns, 'lama menjabat' -->
                      <template v-if="value != 'null' && value != ''">
                      <!-- <td class="pr-2 pb-2" style="text-transform:capitalize; font-size:12px;" v-if="name == 'nama' || name == 'nip' || name == 'perangkat daerah' || name == 'jabatan' || name == 'jabatan_penyetaraan' || name == 'ketua_pokja' || name == 'anggota_pokja'"> -->
                      <td class="pr-2 pb-2" style="text-transform:capitalize; font-size:12px;" v-if="name == 'nama' || name == 'nip' || name == 'perangkat daerah' || name == 'jabatan' || name == 'jabatan_penyetaraan'">
                        <div v-if="name == 'jabatan_penyetaraan'">
                          <b>Jabatan</b>
                        </div>
                        <div v-else-if="name == 'ketua_pokja'">
                          <b>Ketua Pokja</b>
                        </div>
                        <div v-else-if="name == 'anggota_pokja'">
                          <b>Anggota Pokja</b>
                        </div>
                        <div v-else>
                          <b>{{name}}</b>
                        </div>
                      </td>
                      <!-- <td class="pr-2 pb-2" style="text-transform:capitalize; font-size:12px;" v-if="name == 'nama' || name == 'nip' || name == 'perangkat daerah' || name == 'jabatan' || name == 'jabatan_penyetaraan' || name == 'ketua_pokja' || name == 'anggota_pokja'"> -->
                      <td class="pr-2 pb-2" style="text-transform:capitalize; font-size:12px;" v-if="name == 'nama' || name == 'nip' || name == 'perangkat daerah' || name == 'jabatan' || name == 'jabatan_penyetaraan'">
                        <div v-if="name == 'ketua_pokja'">
                          <div v-if="value.length > 0">
                            <div v-for="v,i in value" :key="i">
                              <span v-bind:key="i">{{v.nama_pokja}}</span>
                            </div>
                          </div>
                          <div v-else>-</div>
                        </div>
                        <div v-else-if="name == 'anggota_pokja'">
                          <div v-if="value.length > 0">
                            <div v-for="v,i in value" :key="i">
                              <span v-bind:key="i">{{i+1}}. {{v.nama_pokja}}</span>
                            </div>
                          </div>
                          <div v-else>-</div>
                        </div>
                        <div v-else>
                          {{value}}
                        </div>
                      </td>
                      </template>
                      <!-- <td class="pr-2 pb-2" style="text-transform:capitalize; font-size:12px;" v-if="name != 'jenis_pbj' && name != 'jenis_ppns' && name != 'plt_id' && name != 'survey_flag'">{{name}}</td> -->
                      <!-- <td class="font-weight-light" style="font-size:12px" v-if="name != 'jenis_pbj' && name != 'jenis_ppns' && name != 'plt_id'">{{value}}</td> -->
                      <!-- <td class="font-weight-light" style="font-size:12px" v-if="name != 'jenis_pbj' && name != 'jenis_ppns' && name != 'plt_id' && name != 'survey_flag'">{{value}}</td> -->
                    </tr>
                  </table>
                </div>
              </div>
            </base-material-card>
          </v-col>

          <!-- twitter -->
          <v-col md="5" lg="3">
            <base-material-card
              icon="mdi-twitter"
              color="#08a0e9"
            >
              <v-card-text style="height:397px;overflow:auto">
                <a class="twitter-timeline" href="https://twitter.com/bkpsdm_bdg" data-tweet-limit="15" data-width="100%" ></a>
              </v-card-text>
            </base-material-card>
          </v-col>

          <!-- berita -->
          <v-col
            cols="12"
            md="12"
            lg="6"
          >
            <div v-if="loadingBtn.dataBerita">
              <v-row class="mt-5">
                <v-col>
                  <v-skeleton-loader
                  class="mx-auto"
                  min-width="200"
                  type="image, article"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <base-material-card color="teal">
                <template v-slot:heading>
                  <v-tabs v-model="tabs"
                  background-color="transparent"
                  slider-color="white">
                    <v-tab>
                      <v-icon class="mr-2">
                        mdi-newspaper
                      </v-icon>
                      Berita
                    </v-tab>
                    <v-tab class="mr-3">
                      <v-icon class="mr-2">
                        mdi-youtube
                      </v-icon>
                      Youtube
                    </v-tab>
                  </v-tabs>
                </template>

                <v-tabs-items v-model="tabs" class="transparent">
                  <v-tab-item>
                    <v-carousel
                      cycle
                      height="380"
                      hide-delimiter-background
                      show-arrows-on-hover
                    >
                      <v-carousel-item
                        v-for="(slide, i) in carousel"
                        :key="i"
                      >
                      <div v-html="slide.isi" @click="displayCurrent(slide)"></div>
                      </v-carousel-item>
                    </v-carousel>
                  </v-tab-item>
                  <v-tab-item>
                    <iframe width="100%" height="380" src="https://www.youtube.com/embed/videoseries?list=PLdQ62Kr33gEeoz1GIk-pAbDHADw4YoLLj" frameborder="0" allowfullscreen></iframe>
                  </v-tab-item>
                </v-tabs-items>
              </base-material-card>
            </div>
            <!-- <base-material-card>
              <a class="twitter-timeline" href="https://twitter.com/bkppbdg" data-tweet-limit="3" data-width="100%" ></a>
            </base-material-card>> -->
          </v-col>
        </v-row>

        <v-row class="mx-2">
          <v-col
            cols="12"
            md="6"
          >
            <div v-if="loadingBtn.dataSkpTriwulan">
              <v-row class="mt-5">
                <v-col>
                  <v-skeleton-loader
                  class="mx-auto"
                  min-width="200"
                  type="image, article"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <base-material-card color="pink lig hten-1">
                <template v-slot:heading>
                  <div class="display-2">
                    SKP Triwulan
                  </div>

                  <div class="text-subtitle-1">
                    Target skp triwulan
                  </div>
                </template>
                <v-data-table
                  :headers="headerSKP"
                  :items="skp"
                  style="height:325px;overflow:auto"
                  :loading="loadingBtn.dataSkpTriwulan"
                />
              </base-material-card>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div v-if="loadingBtn.dataAktifitas">
              <v-row class="mt-5">
                <v-col>
                  <v-skeleton-loader
                  class="mx-auto"
                  min-width="200"
                  type="image, article"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <base-material-card color="orange darken-3">
                <template v-slot:heading>
                  <div class="display-2">Aktivitas</div>
                </template>

                <aktivitas-calendar v-if="this.current.role != 'walikota'"></aktivitas-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-x
                >
                  <v-card
                    min-width="350px"
                    flat
                    class="mt-0 mb-0"
                  >
                    <v-toolbar
                      :color="selectedEvent.color"
                      dark
                    >
                      <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="selectedOpen = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <!-- <v-card-text>
                      <span v-html="selectedEvent.details"></span>
                    </v-card-text> -->
                  </v-card>
                </v-menu>
              </base-material-card>
            </div>
          </v-col>
        </v-row>

        <!-- short card -->
        <v-row class="mx-2">
          <v-col cols="4">

            <div v-if="loadingBtn.dataJamKehadiran">
              <v-row class="mt-5">
                <v-col>
                  <v-skeleton-loader
                  class="mx-auto"
                  min-width="200"
                  type="article"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <base-material-stats-card
              style="font-size:12px"
              color="primary lighten-1"
              icon="mdi-clock"
              :title="'Jam Kehadiran (SIAP/'+keteranganJamKehadiran+')'"
              :value="jamKehadiran"
              />
            </div>

            <div v-if="loadingBtn.dataJamKehadiran">
              <v-row class="mt-5">
                <v-col>
                  <v-skeleton-loader
                  class="mx-auto"
                  min-width="200"
                  type="article"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <base-material-stats-card
              style="font-size:12px"
              color="primary"
              icon="mdi-clock"
              :title="'Rata-Rata Kehadiran (SIAP/'+keteranganJamKehadiran+')'"
              :value="dataDiri['rata-rata jam kehadiran'] === 'null' ? '-':dataDiri['rata-rata jam kehadiran']+' %'"
              />
            </div>

          </v-col>
          <v-col cols="8" v-if="showDataGaji">
              <base-material-card
              color="green lighten-1"
              icon="mdi-account-cash"

              :title="'Rp. '+jumlahBersih+' ('+ beforeMonth + ')'"
              >
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Nilai Kinerja</th>
                    <th>Uang Kinerja</th>
                    <th>Nilai TTP</th>
                    <th>Total</th>
                    <th>PPNS</th>
                    <th>BARJAS</th>
                    <th>Jumlah Bruto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="gaji">
                    <td>{{gaji.nilai_kinerja}}</td>
                    <td>{{gaji.uang_kinerja | localeString}}</td>
                    <td>{{gaji.nilai_ttp}}</td>
                    <td>{{gaji.uang_ttp |localeString}}</td>
                    <td>{{gaji.uang_ppns |localeString}}</td>
                    <td>{{gaji.uang_barjas |localeString}}</td>
                    <td>{{gaji.uang_bruto |localeString}}</td>
                  </tr>
                  <tr v-else>
                    <td colspan="7" class="text-center">
                      <p class="grey--text">Data kosong</p>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Presensi Absen</th>
                    <th>Perilaku Negatif</th>
                    <th>Total Sebelum Pajak</th>
                    <th>Pajak</th>
                    <th>Jumlah Bersih</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="gaji">
                    <td>{{gaji.persen_kehadiran | localeString}}</td>
                    <td>{{gaji.persen_aktifitas_negatif |localeString}} %</td>
                    <td>{{gaji.uang_sebelum_pajak | localeString}}</td>
                    <td>{{gaji.pajak | localeString}}</td>
                    <td>{{gaji.uang_total | localeString}}</td>
                  </tr>
                  <tr v-else>
                    <td colspan="5" class="text-center">
                      <p class="grey--text">Data kosong</p>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              </base-material-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="hbday" hide-overlay content-class="remove-shadow">
      <v-img src="@/assets/happybirthday.png" class="animate__animated animate__pulse animate__infinite" contain height="500"></v-img>
    </v-dialog>

  <v-dialog v-model="dialogSurvey" max-width="100%" persistent>
    <v-card>
      <v-card-text>
        <v-form ref="formSurvey">
          <h1 class="display-2 my-2">Survey Minat OPD Paling Diminati Dan Tidak Diminati</h1>
          <br>
          <v-layout align="center">
            <span style="width:220px" class="label font-weight-light display-1 my-1">OPD yang paling diminati</span><v-spacer></v-spacer>
            <v-flex><v-autocomplete dense v-model="formSurvey.opd1" :items="listSKPD" :rules="[rules.required]" item-text="text" item-value="value" label="OPD ke-1" outlined ></v-autocomplete></v-flex>
            <v-flex><v-autocomplete dense v-model="formSurvey.opd2" :items="listSKPD" :rules="[rules.required]" item-text="text" item-value="value" label="OPD ke-2" outlined ></v-autocomplete></v-flex>
            <v-flex><v-autocomplete dense v-model="formSurvey.opd3" :items="listSKPD" :rules="[rules.required]" item-text="text" item-value="value" label="OPD ke-3" outlined ></v-autocomplete></v-flex>
          </v-layout>

          <v-layout align="center">
            <span style="width:185px" class="label font-weight-light display-1 my-1"></span><v-spacer></v-spacer>
            <v-textarea v-model.lazy="formSurvey.alasan_opd1"  outlined label="Alasan anda" required hint="Wajib Diisi ! Minimal 25 karakter" :rules="[rules.required, rules.min25]"></v-textarea>
            <v-textarea v-model.lazy="formSurvey.alasan_opd2"  outlined label="Alasan anda" required hint="Wajib Diisi ! Minimal 25 karakter" :rules="[rules.required, rules.min25]"></v-textarea>
            <v-textarea v-model.lazy="formSurvey.alasan_opd3"  outlined label="Alasan anda" required hint="Wajib Diisi ! Minimal 25 karakter" :rules="[rules.required, rules.min25]"></v-textarea>
          </v-layout>

          <v-layout align="center">
            <span style="width:280px" class="label font-weight-light display-1 my-1"></span>
            <file-input style="width:300px" ref="fileInput" :errorInput="fileInputErrorFlag" :helpText="'Proposal 1 (Sebagai Bahan Pertimbangan)'" :uploadUrl="uploadUrl" @delFileUploaded="delFormFile" @fileUploaded="addFormFile1"></file-input>
            <file-input style="width:300px" ref="fileInput" :errorInput="fileInputErrorFlag" :helpText="'Proposal 2 (Sebagai Bahan Pertimbangan)'" :uploadUrl="uploadUrl" @delFileUploaded="delFormFile" @fileUploaded="addFormFile2"></file-input>
            <file-input style="width:300px" ref="fileInput" :errorInput="fileInputErrorFlag" :helpText="'Proposal 3 (Sebagai Bahan Pertimbangan)'" :uploadUrl="uploadUrl" @delFileUploaded="delFormFile" @fileUploaded="addFormFile3"></file-input>
          </v-layout>

          <br>
          <v-divider dark></v-divider>
          <br>

          <v-layout align="center">
            <span style="width:220px" class="label font-weight-light display-1 my-1">OPD yang paling <b>tidak</b> diminati</span><v-spacer></v-spacer>
            <v-flex><v-autocomplete dense v-model="formSurvey.not_opd1" :items="listSKPD" :rules="[rules.required]" item-text="text" item-value="value" label="OPD ke-1" outlined ></v-autocomplete></v-flex>
            <v-flex><v-autocomplete dense v-model="formSurvey.not_opd2" :items="listSKPD" :rules="[rules.required]" item-text="text" item-value="value" label="OPD ke-2" outlined ></v-autocomplete></v-flex>
            <v-flex><v-autocomplete dense v-model="formSurvey.not_opd3" :items="listSKPD" :rules="[rules.required]" item-text="text" item-value="value" label="OPD ke-3" outlined ></v-autocomplete></v-flex>
          </v-layout>

          <v-layout align="center">
            <span style="width:185px" class="label font-weight-light display-1 my-1"></span><v-spacer></v-spacer>
            <v-textarea v-model.lazy="formSurvey.alasan_not_opd1"  outlined label="Alasan anda" required hint="Wajib Diisi ! Minimal 25 karakter" :rules="[rules.required, rules.min25]"></v-textarea>
            <v-textarea v-model.lazy="formSurvey.alasan_not_opd2"  outlined label="Alasan anda" required hint="Wajib Diisi ! Minimal 25 karakter" :rules="[rules.required, rules.min25]"></v-textarea>
            <v-textarea v-model.lazy="formSurvey.alasan_not_opd3"  outlined label="Alasan anda" required hint="Wajib Diisi ! Minimal 25 karakter" :rules="[rules.required, rules.min25]"></v-textarea>
          </v-layout>

        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="sendSurvey()" :loading="loadingBtn.dialogSurvey" class="success" dark>Kirim</v-btn>
        <v-btn @click="dialogSurvey=false">Tutup</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogSurveyMinatJabatan" v-if="!status_submit_survey_minat_jabatan" max-width="100%" persistent>
    <v-card>
      <v-card-text>
        <v-form ref="formSurveyMinatJabatan">
          <h4 class="display-2 my-2">Survey Minat Jabatan Yang Diminati</h4>
          <br>
          <v-layout align="center">
            <span style="width:220px" class="label font-weight-light display-1 my-1">Jabatan <b>promosi</b> yang diminati</span><v-spacer></v-spacer>
            <!-- <v-flex><v-autocomplete dense v-model="formSurveyMinatJabatan.jabatanPromosi1" :items="listJabatanPromosi" item-text="text" item-value="id" return-object label="Pilih Jabatan ke-1" outlined ></v-autocomplete></v-flex>
            <v-flex><v-autocomplete dense v-model="formSurveyMinatJabatan.jabatanPromosi2" :items="listJabatanPromosi2" item-text="text" item-value="id" return-object label="Pilih Jabatan ke-2" outlined ></v-autocomplete></v-flex> -->
            <v-flex><v-autocomplete dense v-model="formSurveyMinatJabatan.jabatanPromosi1" :items="listJabatanPromosi" :rules="[rules.required]" item-text="text" item-value="id" return-object label="Pilih Jabatan ke-1" outlined ></v-autocomplete></v-flex>
            <v-flex><v-autocomplete dense v-model="formSurveyMinatJabatan.jabatanPromosi2" :items="listJabatanPromosi2" :rules="[rules.required]" :noDataText="'Mohon isi dulu Pilihan Jabatan ke-1'" item-text="text" item-value="id" return-object label="Pilih Jabatan ke-2" outlined ></v-autocomplete></v-flex>
            <v-flex><v-autocomplete dense v-model="formSurveyMinatJabatan.jabatanPromosi3" :items="listJabatanPromosi3" :noDataText="'Mohon isi dulu Pilihan Jabatan ke-1 dan Jabatan ke-2'" item-text="text" item-value="id" return-object label="Pilih Jabatan ke-3" outlined ></v-autocomplete></v-flex>
          </v-layout>

          <v-layout align="center">
            <span style="width:185px" class="label font-weight-light display-1 my-1"></span><v-spacer></v-spacer>
            <!-- <v-textarea v-model="alasanJabatanPromosi1"  outlined label="Alasan anda" :rules="formSurveyMinatJabatan.jabatanPromosi1 ? [rules.required, rules.min25] : []"></v-textarea>
            <v-textarea v-model="alasanJabatanPromosi2"  outlined label="Alasan anda" :rules="formSurveyMinatJabatan.jabatanPromosi2 ? [rules.required, rules.min25] : []"></v-textarea> -->
            <v-textarea v-model="formSurveyMinatJabatan.alasanJabatanPromosi1" v-on:input="alasanJabatanPromosi1" outlined label="Alasan anda" :rules="[rules.required, rules.min25]"></v-textarea>
            <v-textarea v-model="formSurveyMinatJabatan.alasanJabatanPromosi2" v-on:input="alasanJabatanPromosi2" outlined label="Alasan anda" :rules="[rules.required, rules.min25]"></v-textarea>
            <v-textarea v-model="formSurveyMinatJabatan.alasanJabatanPromosi3" v-on:input="alasanJabatanPromosi3" outlined label="Alasan anda" :rules="formSurveyMinatJabatan.jabatanPromosi3 ? [rules.required, rules.min25] : []"></v-textarea>
          </v-layout>

          <v-layout align="center">
            <span style="width:220px" class="label font-weight-light display-1 my-1">Jabatan <b>rotasi</b> yang diminati</span><v-spacer></v-spacer>
            <!-- <v-flex><v-autocomplete dense v-model="formSurveyMinatJabatan.jabatanRotasi1" :items="listJabatanRotasi" item-text="text" item-value="id" return-object label="Pilih Jabatan ke-1" outlined ></v-autocomplete></v-flex>
            <v-flex><v-autocomplete dense v-model="formSurveyMinatJabatan.jabatanRotasi2" :items="listJabatanRotasi2" item-text="text" item-value="id" return-object label="Pilih Jabatan ke-2" outlined ></v-autocomplete></v-flex> -->
            <v-flex><v-autocomplete dense v-model="formSurveyMinatJabatan.jabatanRotasi1" :items="listJabatanRotasi" :rules="[rules.required]" item-text="text" item-value="id" return-object label="Pilih Jabatan ke-1" outlined ></v-autocomplete></v-flex>
            <v-flex><v-autocomplete dense v-model="formSurveyMinatJabatan.jabatanRotasi2" :items="listJabatanRotasi2" :rules="[rules.required]" :noDataText="'Mohon isi dulu Pilihan Jabatan ke-1'" item-text="text" item-value="id" return-object label="Pilih Jabatan ke-2" outlined ></v-autocomplete></v-flex>
            <v-flex><v-autocomplete dense v-model="formSurveyMinatJabatan.jabatanRotasi3" :items="listJabatanRotasi3" :noDataText="'Mohon isi dulu Pilihan Jabatan ke-1 dan Jabatan ke-2'" item-text="text" item-value="id" return-object label="Pilih Jabatan ke-3" outlined ></v-autocomplete></v-flex>
          </v-layout>

          <v-layout align="center">
            <span style="width:185px" class="label font-weight-light display-1 my-1"></span><v-spacer></v-spacer>
            <!-- <v-textarea v-model="alasanJabatanRotasi1"  outlined label="Alasan anda" :rules="formSurveyMinatJabatan.jabatanRotasi1 ? [rules.required, rules.min25] : []"></v-textarea>
            <v-textarea v-model="alasanJabatanRotasi2"  outlined label="Alasan anda" :rules="formSurveyMinatJabatan.jabatanRotasi2 ? [rules.required, rules.min25] : []"></v-textarea> -->
            <v-textarea v-model="formSurveyMinatJabatan.alasanJabatanRotasi1" v-on:input="alasanJabatanRotasi1" outlined label="Alasan anda" :rules="[rules.required, rules.min25]"></v-textarea>
            <v-textarea v-model="formSurveyMinatJabatan.alasanJabatanRotasi2" v-on:input="alasanJabatanRotasi2" outlined label="Alasan anda" :rules="[rules.required, rules.min25]"></v-textarea>
            <v-textarea v-model="formSurveyMinatJabatan.alasanJabatanRotasi3" v-on:input="alasanJabatanRotasi3" outlined label="Alasan anda" :rules="formSurveyMinatJabatan.jabatanRotasi3 ? [rules.required, rules.min25] : []"></v-textarea>
          </v-layout>

          <br>
          <v-divider dark></v-divider>

        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="sendSurveyMinatJabatan()" :loading="loadingBtn.dialogSurveyMinatJabatan" class="success" dark>Kirim</v-btn>
        <v-btn v-if="runningYear > currentYear" @click="dialogSurveyMinatJabatan=false">Tutup</v-btn>
        <!-- <v-btn @click="dialogSurveyMinatJabatan=false">Tutup</v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- detail dialog berita -->
  <v-dialog
    v-model="detailDialog"
    max-width="800"
    scrollable
  >
    <v-card v-if="currentDisplayed">
      <v-card-title class="headline">
        {{currentDisplayed.judul}}
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          icon
          fab
          @click="detailDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text style="max-height:calc( 100vh - 50px )">
        <div v-if="currentDisplayed.link">
          <v-img v-for="(link, i) in currentDisplayed.link" :src="link" :key="i"></v-img>
        </div>
        <div v-else>
          <div class="text-body-1" v-html="currentDisplayed.isi"></div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  </v-container>
</template>

<script>
  import 'animate.css'
  import _ from 'lodash'
  import store from '@/store'
  import DashboardService from '@/services/DashboardService'
  import moment from 'moment'
  import AktivitasCalendar from './components/AktivitasCalendar'
  import {defaultRules} from '@/utils/lib'
  import FileInput from '@/components/common/FileInput'
  import NewsService from '@/services/NewsService'
  import _g from '../../global'
  import axios from 'axios'
  import {local} from '@/store/local'
// Utilities
  // import { mapState, mapMutations } from 'vuex'
  import { mapState } from 'vuex'

  export default {
    components:{
      AktivitasCalendar,
      FileInput
    },
    data: () => ({
      jumlahData:5,
      loadedData:0,
      errorMsg:'',

      tabs:0,
      hbday:false,
      jamKehadiran:'-',
      capaianKinerja:'0',

      gaji:{},

      // calendar variables
      focus:'',
      today: new Date(),
      type:'month',
      typeToLabel: {
          month: 'Bulan',
          week: 'Minggu',
          day: 'Hari',
          '4day': '4 Hari',
        },
      events: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      avatarPhoto:'',
      // end calendar variables

      headerSKP:[
      {text:'Sasaran Triwulan',
        value:'sasaran triwulan'},
      {text:'Indikator Kinerja',
        value:'indikator kinerja'},
      {text:'Target Output',
        value:'target output'}
      ],
      skp:[],
      carousel:[
        require('@/assets/noimage.jpeg')
      ],
      dataDiri:{},
      notifikasi:[],
      listSKPD: [
        {value:"4.03.01", text: "Badan Kepegawaian dan Pengembangan Sumber Daya Manusia"},
        {value:"4.05.06", text: "Badan Kesatuan Bangsa dan Politik"},
        {value:"4.02.01", text: "Badan Keuangan dan Aset Daerah"},
        {value:"4.02.03", text: "Badan Pendapatan Daerah"},
        {value:"4.01.01", text: "Badan Perencanaan Pembangunan, Penelitian dan Pengembangan"},
        {value:"2.17.01", text: "Dinas Arsip dan Perpustakaan"},
        {value:"1.05.02", text: "Dinas Kebakaran dan Penanggulangan Bencana"},
        {value:"2.16.01", text: "Dinas Kebudayaan dan Pariwisata"},
        {value:"2.06.01", text: "Dinas Kependudukan dan Pencatatan Sipil"},
        {value:"1.02.01", text: "Dinas Kesehatan"},
        {value:"2.03.01", text: "Dinas Ketahanan Pangan dan Pertanian"},
        {value:"2.01.01", text: "Dinas Ketenagakerjaan"},
        {value:"2.10.01", text: "Dinas Komunikasi dan Informatika"},
        {value:"2.11.01", text: "Dinas Koperasi dan Usaha Kecil dan Menengah"},
        {value:"2.05.01", text: "Dinas Lingkungan Hidup dan Kebersihan"},
        {value:"1.03.01", text: "Dinas Pekerjaan Umum"},
        {value:"2.08.01", text: "Dinas Pemberdayaan Perempuan dan Perlindungan Anak"},
        {value:"2.13.01", text: "Dinas Pemuda dan Olahraga"},
        {value:"2.12.01", text: "Dinas Penanaman Modal dan Pelayanan Terpadu Satu Pintu"},
        {value:"1.03.02", text: "Dinas Penataan Ruang"},
        {value:"1.01.01", text: "Dinas Pendidikan"},
        {value:"2.02.01", text: "Dinas Pengendalian Penduduk dan Keluarga Berencana"},
        {value:"3.06.01", text: "Dinas Perdagangan dan Perindustrian"},
        {value:"2.09.01", text: "Dinas Perhubungan"},
        {value:"1.04.01", text: "Dinas Perumahan dan Kawasan Permukiman, Pertanahan dan Pertamanan"},
        {value:"1.06.01", text: "Dinas Sosial"},
        {value:"4.05.05", text: "Inspektorat Daerah"},
        {value:"4.05.11", text: "Kecamatan Andir"},
        {value:"4.05.29", text: "Kecamatan Antapani"},
        {value:"4.05.27", text: "Kecamatan Arcamanik"},
        {value:"4.05.17", text: "Kecamatan Astana Anyar"},
        {value:"4.05.20", text: "Kecamatan Babakan Ciparay"},
        {value:"4.05.32", text: "Kecamatan Bandung Kidul"},
        {value:"4.05.21", text: "Kecamatan Bandung Kulon"},
        {value:"4.05.13", text: "Kecamatan Bandung Wetan"},
        {value:"4.05.24", text: "Kecamatan Batununggal"},
        {value:"4.05.18", text: "Kecamatan Bojongloa Kaler"},
        {value:"4.05.19", text: "Kecamatan Bojongloa Kidul"},
        {value:"4.05.31", text: "Kecamatan Buahbatu"},
        {value:"4.05.16", text: "Kecamatan Cibeunying Kaler"},
        {value:"4.05.15", text: "Kecamatan Cibeunying Kidul"},
        {value:"4.05.28", text: "Kecamatan Cibiru"},
        {value:"4.05.10", text: "Kecamatan Cicendo"},
        {value:"4.05.08", text: "Kecamatan Cidadap"},
        {value:"4.05.35", text: "Kecamatan Cinambo"},
        {value:"4.05.12", text: "Kecamatan Coblong"},
        {value:"4.05.33", text: "Kecamatan Gedebage"},
        {value:"4.05.26", text: "Kecamatan Kiaracondong"},
        {value:"4.05.23", text: "Kecamatan Lengkong"},
        {value:"4.05.36", text: "Kecamatan Mandalajati"},
        {value:"4.05.34", text: "Kecamatan Panyileukan"},
        {value:"4.05.30", text: "Kecamatan Rancasari"},
        {value:"4.05.22", text: "Kecamatan Regol"},
        {value:"4.05.09", text: "Kecamatan Sukajadi"},
        {value:"4.05.07", text: "Kecamatan Sukasari"},
        {value:"4.05.14", text: "Kecamatan Sumur Bandung"},
        {value:"4.05.25", text: "Kecamatan Ujungberung"},
        {value:"1.02.04", text: "Rumah Sakit Khusus Gigi dan Mulut"},
        {value:"1.02.03", text: "Rumah Sakit Khusus Ibu dan Anak"},
        {value:"1.02.02", text: "Rumah Sakit Umum Daerah"},
        {value:"1.05.01", text: "Satuan Polisi Pamong Praja"},
        {value:"4.05.02", text: "Sekretariat Daerah"},
        {value:"4.05.04", text: "Sekretariat Dewan Perwakilan Rakyat Daerah"},
        // {value:"Sekretaris Daerah", text: "Sekretaris Daerah"},
        // {value:"Kepala Bagian Administrasi Pembangunan", text: "Kepala Bagian Administrasi Pembangunan"},
        // {value:"Kepala Bagian Hukum", text: "Kepala Bagian Hukum"},
        // {value:"Kepala Bagian Kerja Sama", text: "Kepala Bagian Kerja Sama"},
        // {value:"Kepala Bagian Kesejahteraan Rakyat dan Kemasyarakatan", text: "Kepala Bagian Kesejahteraan Rakyat dan Kemasyarakatan"},
        // {value:"Kepala Bagian Organisasi", text: "Kepala Bagian Organisasi"},
        // {value:"Kepala Bagian Pengadaan Barang dan Jasa", text: "Kepala Bagian Pengadaan Barang dan Jasa"},
        // {value:"Kepala Bagian Perekonomian", text: "Kepala Bagian Perekonomian"},
        // {value:"Kepala Bagian Perencanaan, Keuangan dan Kepegawaian", text: "Kepala Bagian Perencanaan, Keuangan dan Kepegawaian"},
        // {value:"Kepala Bagian Protokol dan Komunikasi Pimpinan", text: "Kepala Bagian Protokol dan Komunikasi Pimpinan"},
        // {value:"Kepala Bagian Tata Pemerintahan", text: "Kepala Bagian Tata Pemerintahan"},
        // {value:"Kepala Bagian Umum", text: "Kepala Bagian Umum"}
      ],
      dialogSurvey: false,
      dialogSurveyMinatJabatan:false,
      formSurvey:{file:[],},
      formSurveyMinatJabatan:{file:[],},
      rules:{
        ...defaultRules
      },
      loadingBtn:{},
      // formSurvey:{},
      fileInputErrorFlag:false,
      recentNews:[],
      currentDisplayed:{},
      detailDialog:false,
      lastNews: null,
      listJabatanPromosi:[],
      listJabatanPromosi2:[],
      listJabatanPromosi3:[],
      listJabatanRotasi:[],
      listJabatanRotasi2:[],
      listJabatanRotasi3:[],
      enable_survey_now:false,
      status_submit_survey_minat_jabatan:false,
      skpd_survey_minat_jabatan_promosi_rotasi:false,
      enable_skpd_survey_minat_jabatan_promosi_rotasi:false,
      anggota_not_pokja:[]
      // survey_jawaban:[]
    }),
    watch:{
      'formSurveyMinatJabatan.jabatanPromosi1': function(){
        var arr = []
        var newArr = []
        arr.push(this.formSurveyMinatJabatan.jabatanPromosi1.id)
          for (let i = 0; i < this.listJabatanPromosi.length; i++) {
            const el = this.listJabatanPromosi[i]
            if(!arr.includes(el.id)){
              newArr.push({
                id: el.id,
                text : el.text
              })
            }
          }
          this.listJabatanPromosi2 = newArr
      },
      'formSurveyMinatJabatan.jabatanPromosi2': function(){
        var arr = []
        var newArr = []
        arr.push(this.formSurveyMinatJabatan.jabatanPromosi2.id)
          for (let i = 0; i < this.listJabatanPromosi2.length; i++) {
            const el = this.listJabatanPromosi2[i]
            if(!arr.includes(el.id)){
              newArr.push({
                id: el.id,
                text : el.text
              })
            }
          }
          this.listJabatanPromosi3 = newArr
      },
      'formSurveyMinatJabatan.jabatanRotasi1': function(){
        var arr = []
        var newArr = []
        arr.push(this.formSurveyMinatJabatan.jabatanRotasi1.id)
          for (let i = 0; i < this.listJabatanRotasi.length; i++) {
            const el = this.listJabatanRotasi[i]
            if(!arr.includes(el.id)){
              newArr.push({
                id: el.id,
                text : el.text
              })
            }
          }
          this.listJabatanRotasi2 = newArr
      },
      'formSurveyMinatJabatan.jabatanRotasi2': function(){
        var arr = []
        var newArr = []
        arr.push(this.formSurveyMinatJabatan.jabatanRotasi2.id)
          for (let i = 0; i < this.listJabatanRotasi2.length; i++) {
            const el = this.listJabatanRotasi2[i]
            if(!arr.includes(el.id)){
              newArr.push({
                id: el.id,
                text : el.text
              })
            }
          }
          this.listJabatanRotasi3 = newArr
      },
      loadedData: function(){
        if(this.loadedData == 1){
          let current = store.state.user.current
          //var url_notifikasi = _g.getBaseURLAPIERK(current.year, 'v1/get-notifikasi')
          var url_notifikasi = '/api/get-notifikasi'
        
          let check = store.state.user
          let token = check.data ? check.data.token : store.getters['user/token']
          let hdr = {'x-access-token': token}

          axios.post(url_notifikasi, {
            nip: current.username,
            tahun: current.year
          },{headers: hdr}).then(response => {
            this.enable_survey_now = response.data.enable_survey
            this.status_submit_survey_minat_jabatan = response.data.status_submit_survey_minat_jabatan
            this.skpd_survey_minat_jabatan_promosi_rotasi = response.data.skpd_survey_minat_jabatan_promosi_rotasi
            this.enable_skpd_survey_minat_jabatan_promosi_rotasi = response.data.enable_skpd_survey_minat_jabatan_promosi_rotasi
            this.anggota_not_pokja = response.data.anggota_not_pokja
          }).finally(()=>{
            this.loadingBtn.dataNotifikasi = false
            if(this.username != "walikotabdg" && this.current.role != 'superadmin' && this.username != "walikotabandung" && this.current.role != 'walikota'){
              if(this.skpd_survey_minat_jabatan_promosi_rotasi && this.enable_skpd_survey_minat_jabatan_promosi_rotasi && !this.status_submit_survey_minat_jabatan){
                axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/survey-minat/getJabatanPromosi"),{
                  api:1,
                  nip: this.username
                }).then(response => {
                  let res = response.data
                  this.listJabatanPromosi = res.data
                  // this.status_submit_survey_minat_jabatan = response.data.status_submit_survey_minat_jabatan
                  // this.skpd_survey_minat_jabatan_promosi_rotasi = response.data.skpd_survey_minat_jabatan_promosi_rotasi
                  // this.enable_skpd_survey_minat_jabatan_promosi_rotasi = response.data.enable_skpd_survey_minat_jabatan_promosi_rotasi
                  if(res.survey_jawaban){
                    res.survey_jawaban.forEach(e => {
                      if(e.survey_id == 3 && e.pertanyaan_id == 3 && e.no == 1){
                        this.formSurveyMinatJabatan.jabatanPromosi1 = e.jabatan_id
                        // this.alasanJabatanPromosi1 = e.alasan
                        this.formSurveyMinatJabatan.alasanJabatanPromosi1 = e.alasan
                        var arr = []
                        var newArr = []
                        arr.push(e.jabatan_id)
                        for (let i = 0; i < this.listJabatanPromosi.length; i++) {
                          const el = this.listJabatanPromosi[i]
                          if(!arr.includes(el.id)){
                            newArr.push({
                              id: el.id,
                              text : el.text
                            })
                          }
                        }
                        this.listJabatanPromosi2 = newArr
                      }
                      if(e.survey_id == 3 && e.pertanyaan_id == 3 && e.no == 2){
                        this.formSurveyMinatJabatan.jabatanPromosi2 = e.jabatan_id
                        // this.alasanJabatanPromosi2 = e.alasan
                        this.formSurveyMinatJabatan.alasanJabatanPromosi2 = e.alasan
                        var arr2 = []
                        var newArr2 = []
                        arr2.push(e.jabatan_id)
                        for (let i = 0; i < this.listJabatanPromosi2.length; i++) {
                          const el = this.listJabatanPromosi2[i]
                          if(!arr2.includes(el.id)){
                            newArr2.push({
                              id: el.id,
                              text : el.text
                            })
                          }
                        }
                        this.listJabatanPromosi3 = newArr2
                      }
                      if(e.survey_id == 3 && e.pertanyaan_id == 3 && e.no == 3){
                        this.formSurveyMinatJabatan.jabatanPromosi3 = e.jabatan_id
                        // this.alasanJabatanPromosi3 = e.alasan
                        this.formSurveyMinatJabatan.alasanJabatanPromosi3 = e.alasan
                      }
                      if(e.survey_id == 3 && e.pertanyaan_id == 4 && e.no == 1){
                        this.formSurveyMinatJabatan.jabatanRotasi1 = e.jabatan_id
                        // this.alasanJabatanRotasi1 = e.alasan
                        this.formSurveyMinatJabatan.alasanJabatanRotasi1 = e.alasan
                        var arr_ = []
                        var newArr_ = []
                        arr_.push(e.jabatan_id)
                          for (let i = 0; i < this.listJabatanRotasi.length; i++) {
                            const el = this.listJabatanRotasi[i]
                            if(!arr_.includes(el.id)){
                              newArr_.push({
                                id: el.id,
                                text : el.text
                              })
                            }
                          }
                          this.listJabatanRotasi2 = newArr_
                      }
                      if(e.survey_id == 3 && e.pertanyaan_id == 4 && e.no == 2){
                        this.formSurveyMinatJabatan.jabatanRotasi2 = e.jabatan_id
                        // this.alasanJabatanRotasi2 = e.alasan
                        this.formSurveyMinatJabatan.alasanJabatanRotasi2 = e.alasan
                        var arr__ = []
                        var newArr__ = []
                        arr__.push(e.jabatan_id)
                        for (let i = 0; i < this.listJabatanRotasi2.length; i++) {
                          const el = this.listJabatanRotasi2[i]
                          if(!arr__.includes(el.id)){
                            newArr__.push({
                              id: el.id,
                              text : el.text
                            })
                          }
                        }
                        this.listJabatanRotasi3 = newArr__
                      }
                      if(e.survey_id == 3 && e.pertanyaan_id == 4 && e.no == 3){
                        this.formSurveyMinatJabatan.jabatanRotasi3 = e.jabatan_id
                        // this.alasanJabatanRotasi3 = e.alasan
                        this.formSurveyMinatJabatan.alasanJabatanRotasi3 = e.alasan
                      }
                    });
                  }
                }).finally(()=>{
                  this.loading = false
                }).catch(err => {
                  var error = err.message
                  store.commit('snackbar/setSnack',{message: error, color:'error'})
                })

                axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/survey-minat/getJabatanRotasi"),{
                  api:1,
                  nip: this.username
                }).then(response => {
                  let res = response.data
                  this.listJabatanRotasi = res.data
                  // if(res.skpd_survey_minat_jabatan_promosi_rotasi && res.enable_skpd_survey_minat_jabatan_promosi_rotasi && !res.status_submit_survey_minat_jabatan){
                  //   this.dialogSurveyMinatJabatan = true
                  //   return setTimeout(()=>{
                  //     alert("Mohon isi dahulu survey berikut ini, terima kasih.")
                  //   },1000)
                  // }
                }).finally(()=>{
                  this.loading = false
                }).catch(err => {
                  var error = err.message
                  store.commit('snackbar/setSnack',{message: error, color:'error'})
                })

                this.dialogSurveyMinatJabatan = true
                return setTimeout(()=>{
                  alert("Mohon isi dahulu survey berikut ini, terima kasih.")
                },1000)
              }
            }
          }).catch(err => { this.errorMsg = 'Gagal mendapatkan data notifikasi'; this.loadingBtn.dataNotifikasi = false})
        }
      }
    },
    computed:{
      dataDiriCloned(){
        let cloned = _.clone(this.dataDiri)
        delete cloned['foto']
        delete cloned['rata-rata jam kehadiran']
        return cloned
      },

      jumlahBersih(){
        try{
          return this.gaji.uang_total.toLocaleString()
        } catch(err){
          return 0
        }
      },

      beforeMonth(){
        moment.locale('id')
        var today = new Date();
        var date = today.getFullYear()+'-'+this.gaji.bulan+'-'+today.getDate();
        return today.getDate() > 6 ? moment(date).format('MMMM YYYY') : "Nilai belum final"
      },

      keteranganJamKehadiran(){
        moment.locale('id')
        var today = new Date();
        return today.getDate() < 11 ? 'Bulan Kemarin' : 'Bulan Ini'
      },

      activateBtn(){
        if (this.dataDiri.nip){
          let matched = this.dataDiri.nip.match(/^\d{4}(\d{4})/)

          if (matched){
            let curr= moment().format('MMDD')
            return matched[1] === curr
          }
        }
        return false
      },

      uploadUrl(){
        return process.env.VUE_APP_API_URL + '/api/ms/3/upload-survey/'
      },

      ...mapState({
        username: state=> state.user.current.username,
        user: state=> state.user.dataPegawai,
        current: state=> state.user.current
      }),

      currentYear(){
        return store.state.user.current.year
      },

      runningYear(){
        var date = new Date()
        return date.getFullYear()
      },

      alasanJabatanPromosi1: {
        get() { return this.formSurveyMinatJabatan.alasanJabatanPromosi1; },
        set: _.debounce(function(value){
          this.formSurveyMinatJabatan.alasanJabatanPromosi1 = value
        })
      },
      alasanJabatanPromosi2: {
        get() { return this.formSurveyMinatJabatan.alasanJabatanPromosi2; },
        set: _.debounce(function(value){
          this.formSurveyMinatJabatan.alasanJabatanPromosi2 = value
        })
      },
      alasanJabatanPromosi3: {
        get() { return this.formSurveyMinatJabatan.alasanJabatanPromosi3; },
        set: _.debounce(function(value){
          this.formSurveyMinatJabatan.alasanJabatanPromosi3 = value
        })
      },
      alasanJabatanRotasi1: {
        get() {return this.formSurveyMinatJabatan.alasanJabatanRotasi1;},
        set: _.debounce(function(value){
          this.formSurveyMinatJabatan.alasanJabatanRotasi1 = value
        })
      },
      alasanJabatanRotasi2: {
        get() {return this.formSurveyMinatJabatan.alasanJabatanRotasi2;},
        set: _.debounce(function(value){
          this.formSurveyMinatJabatan.alasanJabatanRotasi2 = value
        })
      },
      alasanJabatanRotasi3: {
        get() {return this.formSurveyMinatJabatan.alasanJabatanRotasi3;},
        set: _.debounce(function(value){
          this.formSurveyMinatJabatan.alasanJabatanRotasi3 = value
        })
      },
    },
    filters:{
      localeString : function(val){
        try{
          return val.toLocaleString()
        }catch(err){
          return val
        }
      }
    },
    created(){
      var data = []
          data.push({
            "survey_id" : 2,
            "pertanyaan_id" : 1,
            "jawaban" : null,
            "jawaban_kolok" : 'tes',
            "jawaban_kojab" : null,
            "alasan" : 'tes',
            "file" : 'tes',
            "nip" : 'tes',
            "kolok" : null,
            "kojab" : null,
            "eselon" : null,
          })
      this.checkTanggal()
      console.log('calling dashboard service')
      // DashboardService.getGaji().then(response => {
      //   this.loadedData += 1
      //   try{
      //     this.gaji = response.data.data[0]
      //   }catch(error){
      //     console.error(error)
      //   }
      // }).catch(()=>this.errorMsg='Gagal mendapatkan data gaji')

      let user = store.state.user
      this.loadingBtn.dataPegawai = true
      DashboardService.pegawai2(user.current.year, user.data.token).then(response => {
        this.loadedData += 1
        let pegawai = response.data.pegawai[0]
        user.dataPegawai = pegawai
        if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
          pegawai.jabatan = user.current.role
          pegawai.jabatan_penyetaraan = "-"
          pegawai.perangkat_daerah = "Pemerintah Kota Bandung"
          pegawai['perangkat daerah'] = "Pemerintah Kota Bandung"
          pegawai.nama = user.current.role
          pegawai.nip = user.current.username
        }else{
          user.current.username = pegawai.nip
        }
        this.dataDiri = _.clone(pegawai)
        this.$store.getters["user/data"].jenis_pbj = this.$store.getters["user/data"].jenis_pbj == undefined ? pegawai.jenis_pbj : this.$store.getters["user/data"].jenis_pbj
        this.$store.getters["user/data"].data_asn = _.clone(pegawai)

        if (pegawai.foto.match('undefined')){
          this.avatarPhoto = process.env.VUE_APP_IMG_URL + pegawai.nip +".jpeg"
        } else {
          this.avatarPhoto = pegawai.foto.replace(/\.jpg$/gi,'.jpeg')
        }
        store.commit('user/SET_AVATAR', this.avatarPhoto)
        this.loadingBtn.dataPegawai = false
      }).catch(err => { this.errorMsg = 'Gagal mendapatkan data pegawai'; this.loadingBtn.dataPegawai = false})

      this.loadingBtn.dataSkpTriwulan = true
      DashboardService.skpTriwulan().then(response=>{
        this.loadedData += 1
        this.skp = response.data.data
        this.loadingBtn.dataSkpTriwulan = false
      }).catch(err => { this.errorMsg = 'Gagal mendapatkan data skp triwulan'; this.loadingBtn.dataSkpTriwulan = false})

      this.loadingBtn.dataJamKehadiran = true
      DashboardService.jamKehadiran().then(response=>{
        this.loadedData += 1
        this.jamKehadiran = response.data.jam_kehadiran
        this.loadingBtn.dataJamKehadiran = false
      }).catch(err => { this.errorMsg = 'Gagal mendapatkan data jam kehadiran'; this.loadingBtn.dataJamKehadiran = false})

      // rata kehadiran sama dengan data dari pegawai()
      // DashboardService.rataKehadiran().then(response=>{
      //   console.log(response.data)
      // })

      // DashboardService.capaianKinerja().then(response=>{
      //   this.loadedData += 1
      //   // bulatkan di dua angka di belakang koma lalu dikonversi menjadi string
      //   this.capaianKinerja = Math.round(response.data.capaian_kinerja_bulan_lalu * 100) / 100 +""
      // }).catch(()=>this.errorMsg='Gagal mendapatkan data capaian kinerja')

      this.loadingBtn.dataAktifitas = true
      DashboardService.aktifitas().then(response =>{
        this.loadedData += 1
        let aktifitas = response.data.aktifitas_absensi
        let events = []

        for (let i = 0; i < aktifitas.length; i++){
          let jam = aktifitas[i].kegiatan.match(/\d+:\d+/)[0]
          let kegiatan = aktifitas[i].kegiatan.match(/[A-z]+\s[A-z]+/)[0]
          let color = ''

          if (kegiatan === 'jam masuk') color = 'indigo'
          else color = 'cyan'

          events.push({
            name:aktifitas[i].kegiatan,
            start:moment(aktifitas[i].tgl+" "+jam, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
            timed:false,
            color:color
          })
        }
        this.events = events
        this.loadingBtn.dataAktifitas = false
      }).catch(err => { this.errorMsg = 'Gagal mendapatkan data aktivitas'; this.loadingBtn.dataAktifitas = false})

      this.loadingBtn.dataBerita = true
      //var url_news = _g.getBaseURLAPIERK(this.currentYear, "v1/news/get")
      var url_news = "/api/news/get"
      axios.post(url_news, {tahun: this.currentYear}).then(response => {
        let news = response.data.data
        let tempRecent = []
        for (let i = 0; i < news.length; i++){
          let article = news[i]
          article['link'] = article.isi.match(/(https?:\/\/[\w./\s-]+)/g)
          tempRecent.push(article)
        }
        // sorting dari id terbesar
        tempRecent.sort((a,b)=>{return b.id - a.id })
        this.carousel = tempRecent
        this.recentNews = tempRecent
        if(tempRecent[0].is_popup === true){
          this.lastNews = tempRecent[0]
        }else{
          this.lastNews = false
        }
      }).finally(()=>{
        if(this.lastNews){
          this.showLastNews()
        }
        this.loadingBtn.dataBerita = false
      }).catch(err => { this.errorMsg = 'Gagal mendapatkan data berita'; this.loadingBtn.dataBerita = false})
      // NewsService.fetchNews().then(response=>{
      //   let news = response.data.data
      //   let tempRecent = []
      //   for (let i = 0; i < news.length; i++){
      //     let article = news[i]
      //     article['link'] = article.isi.match(/(https?:\/\/[\w./\s-]+)/g)
      //     tempRecent.push(article)
      //   }
      //   // sorting dari id terbesar
      //   tempRecent.sort((a,b)=>{return b.id - a.id })
      //   this.carousel = tempRecent
      //   this.recentNews = tempRecent
      //   if(tempRecent[0].is_popup === true){
      //     this.lastNews = tempRecent[0]
      //   }else{
      //     this.lastNews = false
      //   }
      // }).finally(()=>{
      //   if(this.lastNews){
      //     this.showLastNews()
      //   }
      //   this.loadingBtn.dataBerita = false
      // }).catch(err => { this.errorMsg = 'Gagal mendapatkan data berita'; this.loadingBtn.dataBerita = false})

      var enable_survey
      let current = store.state.user.current
      this.loadingBtn.dataNotifikasi = true
      var temp_notif = []
      //var url_notifikasi = _g.getBaseURLAPIERK(current.year, 'v1/get-notifikasi')
      var url_notifikasi = '/api/get-notifikasi'
      
      let check = store.state.user
      let token = check.data ? check.data.token : store.getters['user/token']
      let hdr = {'x-access-token': token}
      
      axios.post(url_notifikasi, {
         nip: current.username,
         tahun: current.year
      },{headers: hdr}).then(response => {
        this.loadedData += 1
        let notifikasi = response.data.data
        this.enable_survey_now = response.data.enable_survey
        // this.status_submit_survey_minat_jabatan = response.data.status_submit_survey_minat_jabatan
        // this.skpd_survey_minat_jabatan_promosi_rotasi = response.data.skpd_survey_minat_jabatan_promosi_rotasi
        // this.enable_skpd_survey_minat_jabatan_promosi_rotasi = response.data.enable_skpd_survey_minat_jabatan_promosi_rotasi
        // // this.survey_jawaban = response.data.survey_jawaban
        // if(response.data.survey_jawaban.length > 0){
        //   response.data.survey_jawaban.forEach(e => {
        //     if(e.survey_id == 3 && e.pertanyaan_id == 3 && e.no == 1){
        //       this.formSurveyMinatJabatan.jabatanPromosi1 = e.jabatan_id
        //       // this.alasanJabatanPromosi1 = e.alasan
        //       this.formSurveyMinatJabatan.alasanJabatanPromosi1 = e.alasan
        //       var arr = []
        //       var newArr = []
        //       arr.push(e.jabatan_id)
        //       for (let i = 0; i < listJabatanPromosi.length; i++) {
        //         const el = listJabatanPromosi[i]
        //         if(!arr.includes(el.id)){
        //           newArr.push({
        //             id: el.id,
        //             text : el.text
        //           })
        //         }
        //       }
        //       listJabatanPromosi2 = newArr
        //       this.listJabatanPromosi2 = listJabatanPromosi2
        //     }
        //     if(e.survey_id == 3 && e.pertanyaan_id == 3 && e.no == 2){
        //       this.formSurveyMinatJabatan.jabatanPromosi2 = e.jabatan_id
        //       // this.alasanJabatanPromosi2 = e.alasan
        //       this.formSurveyMinatJabatan.alasanJabatanPromosi2 = e.alasan
        //       var arr2 = []
        //       var newArr2 = []
        //       arr2.push(e.jabatan_id)
        //       for (let i = 0; i < listJabatanPromosi2.length; i++) {
        //         const el = listJabatanPromosi2[i]
        //         if(!arr2.includes(el.id)){
        //           newArr2.push({
        //             id: el.id,
        //             text : el.text
        //           })
        //         }
        //       }
        //       listJabatanPromosi3 = newArr2
        //       this.listJabatanPromosi3 = listJabatanPromosi3
        //     }
        //     if(e.survey_id == 3 && e.pertanyaan_id == 3 && e.no == 3){
        //       this.formSurveyMinatJabatan.jabatanPromosi3 = e.jabatan_id
        //       // this.alasanJabatanPromosi3 = e.alasan
        //       this.formSurveyMinatJabatan.alasanJabatanPromosi3 = e.alasan
        //     }
        //     if(e.survey_id == 3 && e.pertanyaan_id == 4 && e.no == 1){
        //       this.formSurveyMinatJabatan.jabatanRotasi1 = e.jabatan_id
        //       // this.alasanJabatanRotasi1 = e.alasan
        //       this.formSurveyMinatJabatan.alasanJabatanRotasi1 = e.alasan
        //       var arr_ = []
        //       var newArr_ = []
        //       arr_.push(e.jabatan_id)
        //         for (let i = 0; i < listJabatanRotasi.length; i++) {
        //           const el = listJabatanRotasi[i]
        //           if(!arr_.includes(el.id)){
        //             newArr_.push({
        //               id: el.id,
        //               text : el.text
        //             })
        //           }
        //         }
        //         listJabatanRotasi2 = newArr_
        //         this.listJabatanRotasi2 = listJabatanRotasi2
        //     }
        //     if(e.survey_id == 3 && e.pertanyaan_id == 4 && e.no == 2){
        //       this.formSurveyMinatJabatan.jabatanRotasi2 = e.jabatan_id
        //       // this.alasanJabatanRotasi2 = e.alasan
        //       this.formSurveyMinatJabatan.alasanJabatanRotasi2 = e.alasan
        //       var arr__ = []
        //       var newArr__ = []
        //       arr__.push(e.jabatan_id)
        //       for (let i = 0; i < listJabatanRotasi2.length; i++) {
        //         const el = listJabatanRotasi2[i]
        //         if(!arr__.includes(el.id)){
        //           newArr__.push({
        //             id: el.id,
        //             text : el.text
        //           })
        //         }
        //       }
        //       listJabatanRotasi3 = newArr__
        //       this.listJabatanRotasi3 = listJabatanRotasi3
        //     }
        //     if(e.survey_id == 3 && e.pertanyaan_id == 4 && e.no == 3){
        //       this.formSurveyMinatJabatan.jabatanRotasi3 = e.jabatan_id
        //       // this.alasanJabatanRotasi3 = e.alasan
        //       this.formSurveyMinatJabatan.alasanJabatanRotasi3 = e.alasan
        //     }
        //   });
        // }
        notifikasi.total = notifikasi[0]['total']
        // this.notifikasi = response.data.data
        response.data.data.forEach(el => {
          temp_notif.push(el)
        });
        this.notifikasi = notifikasi
        store.commit('user/SET_NOTIFIKASI', this.notifikasi )
      }).finally(()=>{
        this.loadingBtn.dataNotifikasi = false
      }).catch(err => { this.errorMsg = 'Gagal mendapatkan data notifikasi'; this.loadingBtn.dataNotifikasi = false})

      if(this.current.role != 'walikota'){
        //Cek notifikasi tahun sebelumnya
        var base_url_api_ = _g.getBaseURLAPIERK((parseInt(current.year)-1))
        //const url_notifikasi_ = base_url_api_ + 'v1/get-notifikasi'
        const url_notifikasi_ = '/api/get-notifikasi'

        let check = store.state.user
        let token = check.data ? check.data.token : store.getters['user/token']
        let hdr = {'x-access-token': token}

        axios.post(url_notifikasi_, {
            nip: current.username,
            tahun: current.year
        },{headers: hdr}).then(response => {
          let notifikasi = response.data.data
          enable_survey = response.data.enable_survey
          notifikasi.total = notifikasi[0]['total']
          // this.notifikasi = response.data.data
          var total = 0
          response.data.data.forEach(el => {
            if(el.title != undefined){
              if(el.title.includes('Umpan Balik Perilaku Tahunan','Umpan Balik Perilaku Triwulan 1',
                                  'Umpan Balik Perilaku Triwulan 2','Umpan Balik Perilaku Triwulan 3','Umpan Balik Perilaku Triwulan 4',
                                  'Validasi SKP Tahunan')){
                                    temp_notif.push(el)
                                    total += el.value
                                    this.jumlahData += 1
                                    this.loadedData += 1
                                  }
            }
          });
          this.notifikasi = temp_notif
          this.notifikasi.forEach(el => {
            if(el.tahun == undefined){
              el.tahun = current.year
            }else{
              el.tahun = parseInt(el.tahun)
            }
          });
          // this.notifikasi = temp_notif
          this.notifikasi.total = temp_notif[0].total + total
          store.commit('user/SET_NOTIFIKASI', this.notifikasi )
        }).finally(()=>{
          this.loadingBtn.dataNotifikasi = false
        }).catch(err => { this.errorMsg = 'Gagal mendapatkan data notifikasi'; this.loadingBtn.dataNotifikasi = false})
      }

      var role = this.current.role
      var username = this.current.username
      var survey_flag = this.user.survey_flag
      var jml_survey_jawaban1 = this.user.jml_survey_jawaban1
      var jml_survey_jawaban2 = this.user.jml_survey_jawaban2
      // if(survey_flag != false && role == 'pengawas'){
      if(enable_survey == true){
        if(survey_flag != true || (jml_survey_jawaban1 < 3 && jml_survey_jawaban2 < 3)){
          this.formSurvey.file_1=[]
          this.formSurvey.file_2=[]
          this.formSurvey.file_3=[]
          if(username != "walikotabdg" && role != 'superadmin' && username != "walikotabandung" && this.current.role != 'walikota'){
            this.dialogSurvey = true
            return setTimeout(()=>{
              alert("Mohon isi dahulu survey berikut ini, terima kasih.")
            },1000)
          }
        }
      }
    },
    mounted(){
      if(this.current.role == 'walikota'){
        // this.jumlahData = 4
      }
      let externalScript = document.createElement('script')
      externalScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
      document.head.appendChild(externalScript)
      console.log('extrnal script', externalScript)
    },
    methods:{
      checkTanggal(){
        var today  = new Date();
        if(today.getDate() > 9){
          this.showDataGaji = true
        }else{
          this.showDataGaji = false
        }
        this.showDataGaji = false
        // console.log(today.toLocaleDateString("id-ID")+'  |   '+today.getDate()); // 12/11/2021
      },
      alert(msg){
        store.commit('snackbar/setSnack',{message:msg, color:'error'})
      },
      startHbday(){
        this.hbday=true
        setTimeout(()=>{
          this.hbday=false
        },3000)
      },
      replacePhoto(url){
        this.avatarPhoto = url
        store.commit('user/SET_AVATAR', url)
      },
      // calendar methods
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => this.selectedOpen = true, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      // end calendar methods

      addFormFile1(file){
        this.formSurvey.file_1.push(file)
        console.log(this.formSurvey.file_1)
      },
      addFormFile2(file){
        this.formSurvey.file_2.push(file)
        console.log(this.formSurvey.file_2)
      },
      addFormFile3(file){
        this.formSurvey.file_3.push(file)
        console.log(this.formSurvey.file_3)
      },
      delFormFile(file){
        let regex = /\d+_([\w\s-]+)-[\w\d]+\.(\w+)/
        let filename = file.file.name
        this.formSurvey.file = this.formSurvey.file.filter((val)=>{
          const match = val.match(regex)
          let curr_filename = match[1]+'.'+match[2]
          if (match){
            return filename != curr_filename
          }
          return false
        })
      },
      sendSurvey(){

        // if(this.$refs.formSurvey.validate()){
          this.loadingBtn.dialogSurvey = true
          var data_diminati_1 = []
          data_diminati_1.push({
            "survey_id" : 1,
            "pertanyaan_id" : 1,
            "jawaban" : '',
            "jawaban_kolok" : this.formSurvey.opd1,
            "jawaban_kojab" : '',
            "alasan" : this.formSurvey.alasan_opd1,
            "file" : JSON.stringify(this.formSurvey.file_1),
            "nip" : this.current.username,
            "kolok" : '',
            "kojab" : '',
            "eselon" : '',
          })

          DashboardService.storeSurveyEselon3(data_diminati_1[0]).then(response=>{
            let res = response.data
            if (res.success){
              store.commit('snackbar/setSnack',{message: res.message, color:'success'})
              this.dialogSurvey = false
            }else {
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          }).finally(()=>{
            this.loadingBtn.dialogSurvey = false
            this.dialogSurvey = false
          })

          this.loadingBtn.dialogSurvey = true
          var data_diminati_2 = []
          data_diminati_2.push({
            "survey_id" : 1,
            "pertanyaan_id" : 2,
            "jawaban" : '',
            "jawaban_kolok" : this.formSurvey.opd2,
            "jawaban_kojab" : '',
            "alasan" : this.formSurvey.alasan_opd2,
            "file" : JSON.stringify(this.formSurvey.file_2),
            "nip" : this.current.username,
            "kolok" : '',
            "kojab" : '',
            "eselon" : '',
          })

          DashboardService.storeSurveyEselon3(data_diminati_2[0]).then(response=>{
            let res = response.data
            if (res.success){
              store.commit('snackbar/setSnack',{message: res.message, color:'success'})
              this.dialogSurvey = false
            }else {
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          }).finally(()=>{
            this.loadingBtn.dialogSurvey = false
            this.dialogSurvey = false
          })

          this.loadingBtn.dialogSurvey = true
          var data_diminati_3 = []
          data_diminati_3.push({
            "survey_id" : 1,
            "pertanyaan_id" : 3,
            "jawaban" : '',
            "jawaban_kolok" : this.formSurvey.opd3,
            "jawaban_kojab" : '',
            "alasan" : this.formSurvey.alasan_opd3,
            "file" : JSON.stringify(this.formSurvey.file_3),
            "nip" : this.current.username,
            "kolok" : '',
            "kojab" : '',
            "eselon" : '',
          })

          DashboardService.storeSurveyEselon3(data_diminati_3[0]).then(response=>{
            let res = response.data
            if (res.success){
              store.commit('snackbar/setSnack',{message: res.message, color:'success'})
              this.dialogSurvey = false
            }else {
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          }).finally(()=>{
            this.loadingBtn.dialogSurvey = false
            this.dialogSurvey = false
          })

          // ====================================================================================================
          this.loadingBtn.dialogSurvey = true
          var data_tidak_diminati_1 = []
          data_tidak_diminati_1.push({
            "survey_id" : 2,
            "pertanyaan_id" : 1,
            "jawaban" : '',
            "jawaban_kolok" : this.formSurvey.not_opd1,
            "jawaban_kojab" : '',
            "alasan" : this.formSurvey.alasan_not_opd1,
            "file" : '[]',
            "nip" : this.current.username,
            "kolok" : '',
            "kojab" : '',
            "eselon" : '',
          })

          DashboardService.storeSurveyEselon3(data_tidak_diminati_1[0]).then(response=>{
            let res = response.data
            if (res.success){
              store.commit('snackbar/setSnack',{message: res.message, color:'success'})
              this.dialogSurvey = false
            }else {
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          }).finally(()=>{
            this.loadingBtn.dialogSurvey = false
            this.dialogSurvey = false
          })

          this.loadingBtn.dialogSurvey = true
          var data_tidak_diminati_2 = []
          data_tidak_diminati_2.push({
            "survey_id" : 2,
            "pertanyaan_id" : 2,
            "jawaban" : '',
            "jawaban_kolok" : this.formSurvey.not_opd2,
            "jawaban_kojab" : '',
            "alasan" : this.formSurvey.alasan_not_opd2,
            "file" : '[]',
            "nip" : this.current.username,
            "kolok" : '',
            "kojab" : '',
            "eselon" : '',
          })

          DashboardService.storeSurveyEselon3(data_tidak_diminati_2[0]).then(response=>{
            let res = response.data
            if (res.success){
              store.commit('snackbar/setSnack',{message: res.message, color:'success'})
              this.dialogSurvey = false
            }else {
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          }).finally(()=>{
            this.loadingBtn.dialogSurvey = false
            this.dialogSurvey = false
          })

          this.loadingBtn.dialogSurvey = true
          var data_tidak_diminati_3 = []
          data_tidak_diminati_3.push({
            "survey_id" : 2,
            "pertanyaan_id" : 3,
            "jawaban" : '',
            "jawaban_kolok" : this.formSurvey.not_opd3,
            "jawaban_kojab" : '',
            "alasan" : this.formSurvey.alasan_not_opd3,
            "file" : '[]',
            "nip" : this.current.username,
            "kolok" : '',
            "kojab" : '',
            "eselon" : '',
          })

          DashboardService.storeSurveyEselon3(data_tidak_diminati_3[0]).then(response=>{
            let res = response.data
            if (res.success){
              store.commit('snackbar/setSnack',{message: res.message, color:'success'})
              this.dialogSurvey = false
            }else {
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          }).finally(()=>{
            this.loadingBtn.dialogSurvey = false
            this.dialogSurvey = false
          })
      },
      displayCurrent(item){
        this.detailDialog=true
        this.currentDisplayed = item
      },
      showLastNews(){
        this.currentDisplayed = this.lastNews
        this.detailDialog=true
      },
      openSurvey(){
        this.dialogSurveyMinatJabatan = true
      },
      sendSurveyMinatJabatan(){
        if(this.$refs.formSurveyMinatJabatan.validate()){
          this.loadingBtn.dialogSurveyMinatJabatan = true

          var promise1 = null
          var promise2 = null
          var promise3 = null
          var promise4 = null
          var promise5 = null
          var promise6 = null
          var data = []

          if(this.formSurveyMinatJabatan.jabatanPromosi1){
            var jabatan_promosi_diminati_1 = []
            jabatan_promosi_diminati_1.push({
              "no" : 1,
              "survey_id" : 3,
              "pertanyaan_id" : 3,
              "alasan" : this.formSurveyMinatJabatan.alasanJabatanPromosi1,
              "nip" : this.current.username,
              "jabatan" : this.formSurveyMinatJabatan.jabatanPromosi1.text,
              "jabatan_id" : this.formSurveyMinatJabatan.jabatanPromosi1.id,
            })
            promise1 = axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/store-survey"),{api:1,...jabatan_promosi_diminati_1[0]})
            data.push(promise1)
          }

          if(this.formSurveyMinatJabatan.jabatanPromosi2){
            this.loadingBtn.dialogSurvey = true
            var jabatan_promosi_diminati_2 = []
            jabatan_promosi_diminati_2.push({
              "no" : 2,
              "survey_id" : 3,
              "pertanyaan_id" : 3,
              "jawaban" : this.formSurveyMinatJabatan.jabatanPromosi2,
              "alasan" : this.formSurveyMinatJabatan.alasanJabatanPromosi2,
              "nip" : this.current.username,
              "jabatan" : this.formSurveyMinatJabatan.jabatanPromosi2.text,
              "jabatan_id" : this.formSurveyMinatJabatan.jabatanPromosi2.id
            })
            promise2 = axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/store-survey"),{api:1,...jabatan_promosi_diminati_2[0]})
            data.push(promise2)
          }

          if(this.formSurveyMinatJabatan.jabatanPromosi3){
            this.loadingBtn.dialogSurvey = true
            var jabatan_promosi_diminati_3 = []
            jabatan_promosi_diminati_3.push({
              "no" : 3,
              "survey_id" : 3,
              "pertanyaan_id" : 3,
              "jawaban" : this.formSurveyMinatJabatan.jabatanPromosi3,
              "alasan" : this.formSurveyMinatJabatan.alasanJabatanPromosi3,
              "nip" : this.current.username,
              "jabatan" : this.formSurveyMinatJabatan.jabatanPromosi3.text,
              "jabatan_id" : this.formSurveyMinatJabatan.jabatanPromosi3.id
            })
            promise3 = axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/store-survey"),{api:1,...jabatan_promosi_diminati_3[0]})
            data.push(promise3)
          }


          // ====================================================================================================
          if(this.formSurveyMinatJabatan.jabatanRotasi1){
            this.loadingBtn.dialogSurvey = true
            var jabatan_rotasi_diminati_1 = []
            jabatan_rotasi_diminati_1.push({
              "no" : 1,
              "survey_id" : 3,
              "pertanyaan_id" : 4,
              "jawaban" : this.formSurveyMinatJabatan.jabatanRotasi1,
              "alasan" : this.formSurveyMinatJabatan.alasanJabatanRotasi1,
              "nip" : this.current.username,
              "jabatan" : this.formSurveyMinatJabatan.jabatanRotasi1.text,
              "jabatan_id" : this.formSurveyMinatJabatan.jabatanRotasi1.id
            })
            promise4 = axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/store-survey"),{api:1,...jabatan_rotasi_diminati_1[0]})
            data.push(promise4)
          }

          if(this.formSurveyMinatJabatan.jabatanRotasi2){
            this.loadingBtn.dialogSurvey = true
            var jabatan_rotasi_diminati_2 = []
            jabatan_rotasi_diminati_2.push({
              "no" : 2,
              "survey_id" : 3,
              "pertanyaan_id" : 4,
              "jawaban" : this.formSurveyMinatJabatan.jabatanRotasi2,
              "alasan" : this.formSurveyMinatJabatan.alasanJabatanRotasi2,
              "nip" : this.current.username,
              "jabatan" : this.formSurveyMinatJabatan.jabatanRotasi2.text,
              "jabatan_id" : this.formSurveyMinatJabatan.jabatanRotasi2.id
            })
            promise5 = axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/store-survey"),{api:1,...jabatan_rotasi_diminati_2[0]})
            data.push(promise5)
          }

          if(this.formSurveyMinatJabatan.jabatanRotasi3){
            this.loadingBtn.dialogSurvey = true
            var jabatan_rotasi_diminati_3 = []
            jabatan_rotasi_diminati_3.push({
              "no" : 3,
              "survey_id" : 3,
              "pertanyaan_id" : 4,
              "jawaban" : this.formSurveyMinatJabatan.jabatanRotasi3,
              "alasan" : this.formSurveyMinatJabatan.alasanJabatanRotasi3,
              "nip" : this.current.username,
              "jabatan" : this.formSurveyMinatJabatan.jabatanRotasi3.text,
              "jabatan_id" : this.formSurveyMinatJabatan.jabatanRotasi3.id
            })
            promise6 = axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/store-survey"),{api:1,...jabatan_rotasi_diminati_3[0]})
            data.push(promise6)
          }

          axios.all(data)
          .then(axios.spread((...responses) => {
            responses.forEach(element => {
              let res = element.data  
              if (res.success){
                store.commit('snackbar/setSnack',{message: res.message, color:'success'})
              }else {
                store.commit('snackbar/setSnack',{message: res.message, color:'error'})
              }
              return true
            });
          })).finally(()=>{
            this.loadingBtn.dialogSurveyMinatJabatan = false
            this.dialogSurveyMinatJabatan = false
          }).catch(err => {
            var error = err.message
            store.commit('snackbar/setSnack',{message: error, color:'error'})
          })

        }
      }
   }  
  }
</script>

<style lang="scss">
  .label-data{
    display:inline-block;
    width:120px;
    overflow:hidden;
    vertical-align: bottom;
  }
  .remove-shadow{
    box-shadow:none !important;
    overflow: hidden;
  }
  .confetti {
    width: 15px;
    height: 15px;
    background-color: #f2d74e;
    position: absolute;
    left: 50%;
    animation: confetti 5s ease-in-out -2s infinite;
    transform-origin: left top;
    z-index:9999;
  }
  .confetti:nth-child(1) {
    background-color: #f2d74e; left: 10%; animation-delay: 0;
  }
  .confetti:nth-child(2) {
    background-color: #95c3de; left: 20%; animation-delay: -5s;
  }
  .confetti:nth-child(3) {
    background-color: #ff9a91; left: 30%; animation-delay: -3s;
  }
  .confetti:nth-child(4) {
    background-color: #f2d74e; left: 40%; animation-delay: -2.5s;
  }
  .confetti:nth-child(5) {
    background-color: #95c3de; left: 50%; animation-delay: -4s;
  }
  .confetti:nth-child(6) {
    background-color: #ff9a91; left: 60%; animation-delay: -6s;
  }
  .confetti:nth-child(7) {
    background-color: #f2d74e; left: 70%; animation-delay: -1.5s;
  }
  .confetti:nth-child(8) {
    background-color: #95c3de; left: 80%; animation-delay: -2s;
  }
  .confetti:nth-child(9) {
    background-color: #ff9a91; left: 90%; animation-delay: -3.5s;
  }
  .confetti:nth-child(10) {
    background-color: #f2d74e; left: 100%; animation-delay: -2.5s;
  }

  @keyframes confetti {
    0% { transform: rotateZ(15deg) rotateY(0deg) translate(0,0); }
    25% { transform: rotateZ(5deg) rotateY(360deg) translate(-5vw,20vh); }
    50% { transform: rotateZ(15deg) rotateY(720deg) translate(5vw,60vh); }
    75% { transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw,80vh); }
    100% { transform: rotateZ(15deg) rotateY(1440deg) translate(10vw,110vh); }
  }
</style>
